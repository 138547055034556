import React from 'react'

const Women = () => {
    return (
        <div className="no-gutters banner women">
            
        </div>
    )
}


export default Women
