import React from 'react'

const Men = () => {
    return (
        <div className="no-gutters banner men">
            
        </div>
    )
}

export default Men
