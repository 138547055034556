import React from 'react'

const Intro = () => {
    return (
        
        <div className="no-gutters banner intro">
        </div>
    )
}

export default Intro
