import React from 'react'
import Welcome from './Welcome'

const About = () => {
    return (
        <div className="mt-5">
        <Welcome/>
        </div>
    )
}

export default About
